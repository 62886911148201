import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CreateUpdateInventoryModalComponent} from '../create-update-inventory-modal/create-update-inventory-modal.component';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-inventory-list-page',
  templateUrl: './inventory-list-page.component.html',
  styleUrls: ['./inventory-list-page.component.scss']
})
export class InventoryListPageComponent implements OnInit {
  inventoryItems: any[] = [];
  private activeItemId: number;

  constructor(private modalService: NgbModal,
              private http: HttpClient,
              private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.activeItemId = params.itemId;
      this.updateInventoryItems();
    });
  }

  private updateInventoryItems() {
    this.http.get<any[]>('/api/inventory-items')
      .toPromise()
      .then(items => {
        items.forEach(item => {
          item.createdAt = item.createdAt && new Date(item.createdAt);
          item.expiredAt = item.expiredAt && new Date(item.expiredAt);
        });
        this.inventoryItems = items;

        if (this.activeItemId) {
          setTimeout(() => {
            const el = document.getElementById('item-' + this.activeItemId);
            if (el) {
              el.scrollIntoView();
            }
          }, 0);
        }
      });
  }

  openUpdateInventoryItemModal(item: any) {
    const modalRef = this.modalService.open(CreateUpdateInventoryModalComponent, {size: 'lg'});
    modalRef.componentInstance.item = item;
  }

  removeWeight(id: number) {
    this.http.delete('/api/inventory-items/' + id + '/weight')
      .toPromise()
      .then(() => this.updateInventoryItems());
  }
}
