import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {UserService} from '../../user/user.service';


@Component({
  selector: 'app-create-update-inventory-modal',
  templateUrl: './weight-inventory-item-modal.component.html',
  styleUrls: ['./weight-inventory-item-modal.component.scss']
})
export class WeightInventoryItemModalComponent implements OnInit {
  @Input() item: any = {};
  userName: string;
  isLoading = true;

  constructor(public activeModal: NgbActiveModal,
              private http: HttpClient,
              private router: Router,
              private userService: UserService) {
    userService.getActiveUser().subscribe(user => this.userName = user.name);
  }

  ngOnInit() {
    setTimeout(() => {
      this.isLoading = false;
      setTimeout(() => this.activeModal.close(), 10000);
    }, 3000);
  }

  navigateToItem() {
    this.activeModal.close();
    this.router.navigate(['/', 'inventory'], {queryParams: {itemId: this.item.id, hash: Math.random()}})
      .then(() => this.router.navigate(['/', 'inventory'], {queryParams: {itemId: this.item.id}}));
  }
}
