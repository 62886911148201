import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-journal-entry-content',
  templateUrl: './journal-entry-content.component.html',
  styleUrls: ['./journal-entry-content.component.scss']
})
export class JournalEntryContentComponent implements OnInit {
  @Input() journalEntry: any;

  constructor() { }

  ngOnInit() {
  }

}
