import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {Action} from './action';
import {ShowCustomModalAction} from './ShowCustomModal.action';
import {ShowRegisterNewItemModalAction} from './ShowRegisterNewItemModal.action';
import {ChangeActiveUserAction} from './ChangeActiveUser.action';
import {UserService} from '../user/user.service';
import {ShowRegularItemWeightModalAction} from './ShowRegularItemWeightModal.action';
import {ShowExperimentModalAction} from './ShowExperimentModal.action';
import {InventoryItemCustomEventAction} from './InventoryItemCustomEvent.action';

@Injectable({
  providedIn: 'root'
})
export class ActionService {
  private actions: Map<string, Action> = new Map<string, Action>();

  constructor(private http: HttpClient,
              private modalService: NgbModal,
              private userService: UserService) {
    this.makeRequest();
    this.actions.set('ShowCustomModalAction', new ShowCustomModalAction(this.modalService));
    this.actions.set('ShowRegisterNewItemModalAction', new ShowRegisterNewItemModalAction(this.modalService));
    this.actions.set('ChangeActiveUserAction', new ChangeActiveUserAction(this.userService));
    this.actions.set('ShowRegularItemWeightModalAction', new ShowRegularItemWeightModalAction(this.modalService));
    this.actions.set('ShowExperimentModalAction', new ShowExperimentModalAction(this.modalService));
    this.actions.set('InventoryItemCustomEventAction', new InventoryItemCustomEventAction(this.modalService));
  }


  makeRequest(version?: number) {
    this.http.get<any>('/api/actions' + (version ? ('?version=' + version) : ''))
      .toPromise()
      .then(actionResponse => {
        for (const actionData of actionResponse.actions) {
          this.actions.get(actionData.type).execute(actionData);
        }
        this.makeRequest(actionResponse.version);
      }, () => {
        this.makeRequest();
      });
  }
}
