import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardPageComponent} from './dashboard/dashboard-page/dashboard-page.component';
import {ExperimentListPageComponent} from './experiment/experiment-list-page/experiment-list-page.component';
import {InventoryListPageComponent} from './inventory/inventory-list-page/inventory-list-page.component';
import {JournalPageComponent} from './journal/journal-page/journal-page.component';


const routes: Routes = [
  {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
  {path: 'dashboard', component: DashboardPageComponent},
  {path: 'experiments', component: ExperimentListPageComponent},
  {path: 'inventory', component: InventoryListPageComponent},
  {path: 'journal', component: JournalPageComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
