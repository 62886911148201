import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {Action} from './action';
import {CreateUpdateInventoryModalComponent} from '../inventory/create-update-inventory-modal/create-update-inventory-modal.component';
import {WeightInventoryItemModalComponent} from '../inventory/weight-inventory-item-modal/weight-inventory-item-modal.component';

export class ShowRegularItemWeightModalAction implements Action {
  constructor(private modalService: NgbModal) {
  }

  execute(data: any) {
    const modal = this.modalService.open(WeightInventoryItemModalComponent);
    modal.componentInstance.item = data.item;
  }
}
