import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalComponent} from '../modal/modal.component';
import {Action} from './action';

export class ShowCustomModalAction implements Action {
  constructor(private modalService: NgbModal) {
  }

  execute(data: any) {
    const modalOptions: NgbModalOptions = {};

    if (data.size) {
      modalOptions.size = data.size;
    }

    const modal = this.modalService.open(ModalComponent, modalOptions);

    modal.componentInstance.headerHtml = data.headerHtml;
    modal.componentInstance.bodyHtml = data.bodyHtml;
    modal.componentInstance.footerHtml = data.footerHtml;
    modal.componentInstance.customModalHtml = data.customModalHtml;
    modal.componentInstance.closeButton = data.closeButton;

    if (data.duration) {
      setTimeout(() => modal.dismiss('Duration time out'), data.duration);
    }
  }
}
