import {Component} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ModalComponent} from '../../modal/modal.component';
import {ExperimentProcessModalComponent} from '../experiment-process-modal/experiment-process-modal.component';

@Component({
  selector: 'app-experiment-list-page',
  templateUrl: './experiment-list-page.component.html',
  styleUrls: ['./experiment-list-page.component.scss']
})
export class ExperimentListPageComponent {
  constructor(private modalService: NgbModal) {}

  open() {
    const modalRef = this.modalService.open(ExperimentProcessModalComponent, {size: 'lg'});
  }

}
