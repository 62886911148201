import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UserService} from '../../user/user.service';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-experiment-process-modal',
  templateUrl: './experiment-process-modal.component.html',
  styleUrls: ['./experiment-process-modal.component.scss']
})
export class ExperimentProcessModalComponent implements OnInit, OnDestroy {
  userName: string;
  currentTime: string;
  currentTimeInterval: number;

  @Input() experiment: any;
  @Input() experimentId: number;

  constructor(public activeModal: NgbActiveModal,
              private userService: UserService,
              private http: HttpClient,
              private router: Router) {
    userService.getActiveUser().subscribe(user => this.userName = user.name);
  }

  ngOnInit() {
    this.updateExperiment();
    this.currentTime = new Date().toLocaleString('en');
    this.currentTimeInterval = setInterval(() => {
      this.currentTime = new Date().toLocaleString('en');
    }, 1000);
  }

  updateExperiment() {
    const version = (this.experiment && this.experiment.version) || 0;

    this.http.get<any>('/api/experiments/' + this.experimentId + '?version=' + version)
      .toPromise()
      .then(e => {
        this.experiment = e;
      }, err => console.error(err))
      .then(() => this.updateExperiment());
  }

  ngOnDestroy(): void {
    clearInterval(this.currentTimeInterval);
  }

  startClicked() {
    this.router.navigate(['/', 'experiments']);
    this.activeModal.close();
  }
}
