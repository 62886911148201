import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {UserService} from '../../user/user.service';


@Component({
  templateUrl: './digital-twin-storage-items-modal.component.html',
  styleUrls: ['./digital-twin-storage-items-modal.component.scss']
})
export class DigitalTwinStorageItemsModalComponent {
  @Input() items: any[] = [];
  @Input() storage: string;

  constructor(public activeModal: NgbActiveModal) {
  }

}
