import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardPageComponent } from './dashboard/dashboard-page/dashboard-page.component';
import { ExperimentListPageComponent } from './experiment/experiment-list-page/experiment-list-page.component';
import { InventoryListPageComponent } from './inventory/inventory-list-page/inventory-list-page.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from './modal/modal.component';
import { CreateUpdateInventoryModalComponent } from './inventory/create-update-inventory-modal/create-update-inventory-modal.component';
import { ExperimentProcessModalComponent } from './experiment/experiment-process-modal/experiment-process-modal.component';
import { JournalPageComponent } from './journal/journal-page/journal-page.component';
import { HttpClientModule } from '@angular/common/http';
import { ActionService } from './action/action.service';
import {FormsModule} from '@angular/forms';
import {WeightInventoryItemModalComponent} from './inventory/weight-inventory-item-modal/weight-inventory-item-modal.component';
import { DigitalTwinComponent } from './digital-twin/digital-twin/digital-twin.component';
import {DigitalTwinStorageItemsModalComponent} from './digital-twin/digital-twin-storage-items-modal/digital-twin-storage-items-modal.component';
import { JournalEntryContentComponent } from './journal/journal-entry-content/journal-entry-content.component';
import { JournalEntryListComponent } from './journal/journal-entry-list/journal-entry-list.component';

@NgModule({
  declarations: [
    AppComponent,
    ModalComponent,
    DashboardPageComponent,
    ExperimentListPageComponent,
    InventoryListPageComponent,
    JournalPageComponent,
    DigitalTwinComponent,

    ExperimentProcessModalComponent,
    WeightInventoryItemModalComponent,
    CreateUpdateInventoryModalComponent,
    DigitalTwinStorageItemsModalComponent,
    JournalEntryContentComponent,
    JournalEntryListComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule
  ],
  providers: [ActionService],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalComponent,
    ExperimentProcessModalComponent,
    WeightInventoryItemModalComponent,
    CreateUpdateInventoryModalComponent,
    DigitalTwinStorageItemsModalComponent
  ]
})
export class AppModule {
  constructor(actionService: ActionService/*Do not remove this!!!*/) {
  }
}
