import {Action} from './action';
import {UserService} from '../user/user.service';

export class ChangeActiveUserAction implements Action {
  constructor(private userService: UserService) {
  }

  execute(data: any) {
    this.userService.changeActiveUser(data.user);
  }
}
