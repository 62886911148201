import { Component } from '@angular/core';
import {UserService} from './user/user.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private userService: UserService) {
  }

  get user(): Observable<any> {
    return this.userService.getActiveUser().pipe(map(u => u.name));
  }

}
