import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private activeUser: BehaviorSubject<any> = new BehaviorSubject({
    id: 0,
    name: 'Loading...'
  });

  constructor(private http: HttpClient) {
    this.http.get('/api/users/active')
      .toPromise()
      .then(user => this.activeUser.next(user));
  }

  getActiveUser(): BehaviorSubject<any> {
    return this.activeUser;
  }

  changeActiveUser(user: any) {
    this.activeUser.next(user);
  }
}
