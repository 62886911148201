import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalComponent} from '../modal/modal.component';
import {Action} from './action';
import {ExperimentProcessModalComponent} from '../experiment/experiment-process-modal/experiment-process-modal.component';

export class ShowExperimentModalAction implements Action {
  constructor(private modalService: NgbModal) {
  }

  execute(data: any) {
    const modalRef = this.modalService.open(ExperimentProcessModalComponent, {size: 'lg'});
    modalRef.componentInstance.experimentId = data.experimentId;
  }
}
