import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  @Input() headerHtml: string;
  @Input() bodyHtml: string;
  @Input() footerHtml: string;
  @Input() customModalHtml: string;
  @Input() closeButton: boolean;

  constructor(public activeModal: NgbActiveModal) {}
}
