import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {UserService} from '../../user/user.service';


@Component({
  selector: 'app-create-update-inventory-modal',
  templateUrl: './create-update-inventory-modal.component.html',
  styleUrls: ['./create-update-inventory-modal.component.scss']
})
export class CreateUpdateInventoryModalComponent implements OnInit {
  @Input() item: any = {};
  userName: string;

  constructor(public activeModal: NgbActiveModal,
              private http: HttpClient,
              private router: Router,
              private userService: UserService) {
    userService.getActiveUser().subscribe(user => this.userName = user.name);
  }

  ngOnInit() {
    const date = new Date();
    function twoDigits(n: number) {
      if (n < 10) {
        return '0' + n;
      }
      return n;
    }

    this.item.createdAt = date.getFullYear() + '-' + twoDigits(date.getMonth() + 1) + '-' + twoDigits(date.getDate());
  }

  create() {
    if (!this.item.title) {
      alert('Title is a required field');
      return;
    }
    if (!this.item.amount || parseInt(this.item.amount, 10) < 1) {
      alert('Amount is a required field and it`s value should be a positive number');
      return;
    }
    this.item.currentUserId = this.userService.getActiveUser().getValue().id;
    this.http.post<any>('/api/inventory-items', this.item)
      .toPromise()
      .then(res => {
        this.activeModal.close();
        this.router.navigate(['/', 'inventory'], {queryParams: {itemId: res.id, hash: Math.random()}})
          .then(() => this.router.navigate(['/', 'inventory'], {queryParams: {itemId: res.id}}));
      }, err => {
        console.error(err);
        alert(err.message);
      });
  }
}
