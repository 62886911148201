import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalComponent} from '../modal/modal.component';
import {Action} from './action';

export class InventoryItemCustomEventAction implements Action {
  constructor(private modalService: NgbModal) {
  }

  execute(data: any) {
    const modalOptions: NgbModalOptions = {};

    const modal = this.modalService.open(ModalComponent, modalOptions);

    modal.componentInstance.headerHtml = 'Safety Violation';
    modal.componentInstance.bodyHtml = data.limsMessage;
    modal.componentInstance.closeButton = false;

    if (data.duration) {
      setTimeout(() => modal.dismiss('Duration time out'), data.duration);
    }
  }
}
