import {Component, Input, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-journal-entry-list',
  templateUrl: './journal-entry-list.component.html',
  styleUrls: ['./journal-entry-list.component.scss']
})
export class JournalEntryListComponent implements OnInit {
  @Input() maxItems;
  @Input() safetyOnly;
  journalEntries: any[];

  constructor(private http: HttpClient) {
  }

  ngOnInit() {
    this.updateJournal();
  }

  updateJournal() {
    let url = '/api/journal-entries?size=' + this.maxItems;
    if (this.safetyOnly) {
      url += '&types=InventoryItemCustomEventAction';
    }

    this.http.get<any[]>(url)
      .toPromise()
      .then(journalEntries => {
        for (const e of journalEntries) {
          e.content = JSON.parse(e.content);
          e.createdAt = new Date(e.createdAt);
        }

        this.journalEntries = journalEntries;
      }, err => console.error(err))
      .then(() => setTimeout(() => this.updateJournal(), 5000));
  }
}
