import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {DigitalTwinStorageItemsModalComponent} from '../digital-twin-storage-items-modal/digital-twin-storage-items-modal.component';

@Component({
  selector: 'app-digital-twin',
  templateUrl: './digital-twin.component.html',
  styleUrls: ['./digital-twin.component.scss']
})
export class DigitalTwinComponent implements OnInit, OnDestroy {
  digitalTwin: any;
  storageConfig = [
    {name: 'scale', top: 52, left: 12},
    {name: 'shelve1', top: 25, left: 34},
    {name: 'shelve2', top: 25, left: 56},
    {name: 'shelve3', top: 62, left: 34},
    {name: 'shelve4', top: 62, left: 56},
    {name: 'table', top: 58, left: 83}
  ];
  private isDestroyed = false;

  constructor(private modalService: NgbModal,
              private http: HttpClient) {
  }

  ngOnInit() {
    this.updateDigitalTwinState();
  }

  updateDigitalTwinState() {
    const version = (this.digitalTwin && this.digitalTwin.version) || 0;
    this.http.get('/api/digital-twin?version=' + version)
      .toPromise()
      .then(digitalTwin => {
        this.digitalTwin = digitalTwin;
      }, err => console.log(err))
      .then(() => {
        if (!this.isDestroyed) {
          this.updateDigitalTwinState();
        }
      });
  }

  storageClick(storage) {
    if (this.digitalTwin[storage].length === 0 ) {
      return;
    }

    const modal = this.modalService.open(DigitalTwinStorageItemsModalComponent);
    modal.componentInstance.items = this.digitalTwin[storage];
    modal.componentInstance.storage = storage;
  }

  ngOnDestroy(): void {
    this.isDestroyed = true;
  }
}
