import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {Action} from './action';
import {CreateUpdateInventoryModalComponent} from '../inventory/create-update-inventory-modal/create-update-inventory-modal.component';

export class ShowRegisterNewItemModalAction implements Action {
  constructor(private modalService: NgbModal) {
  }

  execute(data: any) {
    const modal = this.modalService.open(CreateUpdateInventoryModalComponent, {size: 'lg'});
    modal.componentInstance.item = {tagId: data.tagId};
  }
}
